jQuery(function($) {

AOS.init({
  offset: 120
});

// Ken Burns Treatment Page Header
/*var $img = $('#hero');
var freewayEaseTween = new TimelineMax({
  paused:true,
  yoyo:false,
});
freewayEaseTween
.set($img,{backgroundSize:"100% 100%"})
.to($img, 20, {
  backgroundSize: "+=25% +=25%", 
  autoRound:false, 
  ease: Power1.ease0ut
})
.progress(1).progress(0)
.play(); 
*/

var gsap = new TimelineLite();
var spd = 20
gsap.to($('#hero-image'), spd, {scale: 1.4, x:-10 })


});

